import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { GlobalStyle } from './globalStyle'
import Header from './header'
import Footer from './footer'
import '../styles/global.css'

const Layout = ({ children }) => {
  console.log(
    '%cDesign & Develop by adrien-m.com',
    'color: #8000ff; font-family:monospace; font-size: 18px; padding: 10px 15px; background-color: #80ff00; border-radius: 5px; margin: 20px 0;'
  )

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
          prismicInfo {
            data {
              address {
                text
              }
              adress_link {
                url
              }
              email {
                text
              }
              instagram {
                text
              }
              instagram_link {
                url
              }
              phone {
                text
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <GlobalStyle />
          <MainWrapper>
            <Header siteTitle={data.site.siteMetadata.title} />
            <main>{children}</main>
            <Footer footerInfo={data.prismicInfo.data} />
          </MainWrapper>
          <Title>Jamie Brown</Title>
        </>
      )}
    />
  )
}

const MainWrapper = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`

const Title = styled.h1`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  font-size: 25px;
  font-weight: 300;
  pointer-events: none;
  color: #000;
  transition: all 0.2s cubic-bezier(0.05, 0.16, 0.05, 0.95);
  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
