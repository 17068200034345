import React from 'react'
import styled from 'styled-components'

const Footer = ({ className, footerInfo }) => (
  <FooterWrap className={className}>
    <Container>
      <p>
        <ContactLink href={`mailto:${footerInfo.email.text}?subject=Hello%20Jamie`}>
          {footerInfo.email.text}
        </ContactLink>
        •
        <ContactLink href={footerInfo.adress_link.url} target="_blank" rel="noopener noreferrer">
          {footerInfo.address.text}
        </ContactLink>
        •
        <br />
        <ContactLink href={`tel:${footerInfo.phone.text}`}>{footerInfo.phone.text}</ContactLink>•
        <ContactLink href={footerInfo.instagram_link.url} target="_blank" rel="noopener noreferrer">
          {footerInfo.instagram.text}
        </ContactLink>
      </p>
    </Container>
  </FooterWrap>
)

const FooterWrap = styled.footer`
  display: none;

  @media only screen and (min-width: 769px) {
    display: block;
  }
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 0px 1.0875rem;
  padding-top: 0;
  width: auto;
  /* background-color: white; */
  /* background: linear-gradient(to top, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.5) 100%); */
  height: auto;

  br {
    display: block;

    @media screen and (min-width: 891px) {
      display: none;
    }
  }
`

const StyledFooter = styled(Footer)`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  /* max-width: 1000px; */
  margin: 0 auto;
  border-radius: 5px;
  /* border: 1px solid black; */
  color: #000000;
  padding: 0.4rem 0 0.3rem 0;
  z-index: 100;
  text-align: center;
  font-size: 15px;
  font-family: 'Aileron';
  background-color: white;
  /* height: 50px; */
  /* background: linear-gradient(to top, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0) 100%); */
  @media screen and (max-width: 500px) {
    font-size: 13px;
    bottom: 1%;
  }

  p {
    margin-bottom: 0;
  }
`

const ContactLink = styled.a`
  color: #000;
  margin: 0 7px;
  font-weight: 300;
`

export default StyledFooter
