import React from 'react'
import styled from 'styled-components'

import Navigation from './nav'

const HeaderWrap = styled.header`
  position: relative;
  z-index: 1000;
  height: 30px;
`

const Header = () => (
  <HeaderWrap>
    <Navigation />
  </HeaderWrap>
)

export default Header
