import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

const Navigation = () => {
  const [toggle, setToggle] = useState(false)

  const navAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: toggle ? 1 : 0, bottom: toggle ? 35 : -155 },
    config: {
      config: { mass: 1, tension: 100, friction: 10 }
    },
    delay: toggle ? 0 : 200
  })

  const onResizeNav = () => {
    setToggle(window.innerWidth > 768)
  }

  useEffect(() => {
    setToggle(window.innerWidth > 768)
  }, [])

  if (typeof window !== `undefined`) {
    window.addEventListener('resize', onResizeNav)
  }

  return (
    <>
      <Nav style={navAnimation}>
        <NavLink to="/">Work</NavLink>
        <NavLink to="/infos">Info</NavLink>
        <NavLink to="/sales">Sales</NavLink>
      </Nav>
      <ButtonNav
        onClick={() => {
          setToggle(!toggle)
        }}
        className={toggle ? 'open' : 'close'}
      >
        <span></span>
        <span></span>
      </ButtonNav>
    </>
  )
}

const Nav = styled(animated.nav)`
  position: fixed;
  top: 30px;
  left: 30px;
  bottom: unset;
  width: 90px;
  height: 101px;
  margin: 0 auto;
  padding: 0;
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: content-box;
  z-index: 10000;

  /* Small Devices, Tablets */
  @media only screen and (max-width: 768px) {
    opacity: 0;
    bottom: -155px;
    left: 50%;
    top: unset;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 110px;
  }
`

const NavLink = styled(Link)`
  display: inline-block;
  width: 100%;
  padding: 4px 0 4.9px 0;
  text-align: center;
  color: black;
  font-size: 0.85rem;
  line-height: 1.66;
  box-sizing: border-box;
  font-weight: 300;
  border-bottom: 1px solid black;
  transition: color 0.4s cubic-bezier(0.05, 0.16, 0.05, 0.95),
    background 0.4s cubic-bezier(0.05, 0.16, 0.05, 0.95);

  @media only screen and (max-width: 768px) {
    padding: 6px 0;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    /* color: #c0c0c0; */
    color: #ededed;
    /* background: #ededed; */
    /* background: #2f2f2f; */
    background: #1a1a1a;
  }
`

const ButtonNav = styled.button`
  position: fixed;
  bottom: 20px;
  left: 50%;
  background: white;
  border: 0;
  width: 50px;
  height: 50px;
  padding: 15px;
  border: 1px solid black;
  border-radius: 50%;
  transform: translateX(-50%);
  display: block;
  z-index: 10000;

  @media only screen and (min-width: 768px) {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  &.open {
    span:first-child {
      display: block;
      transform: rotate(45deg);
      margin-top: 0px;
    }
  }

  &.open {
    span:last-child {
      transform: rotate(-225deg);
      margin-top: -2px;
    }
  }

  span {
    display: block;
    height: 2px;
    border-radius: 1px;
    width: 100%;
    background-color: black;
    transition: all 0.35s cubic-bezier(0.05, 0.16, 0.05, 0.95);

    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-top: 8px;
    }
  }
`

export default Navigation
